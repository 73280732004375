import React, { useState, useEffect } from 'react';
import {
    Alert,
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { useLoading } from './LoadingContext';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function MovieRunLog() {
    const [data, setData] = useState([]);
    const { showLoading, hideLoading } = useLoading();
    const [open, setOpen] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [sdate, setSData] = useState(dayjs());
    const [executeCmd, setExecuteCmd] = useState("");
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleModalClose = (event, reason) => {

        setModalOpen(false);
    };
    const handleModalOpen = (event, reason) => {

        setModalOpen(true);
    };

    const loadData = async () => {
        showLoading()
        fetch('/api/movie-log')
            .then(response => response.json())
            .then(data => {
                setData(data);
                hideLoading();
            });
    }

    useEffect(() => {
        loadData();
    }, [])

    const handleModalOK = async () => {
        handleModalClose()
        if (executeCmd !== '') {
            await execute(executeCmd);
            setExecuteCmd("")

        }
    }
    const confirm_execute = (script) => {
        setExecuteCmd(script)
        handleModalOpen()
    }

    const execute = async (script) => {
        try {

            showLoading();
            script += ' ' + sdate.format("YYYYMMDD");
            const response = await fetch('/api/execute-bot?cmd=' + script); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            hideLoading();
            setMessage(jsonData.result)
            setOpen(true);
            loadData();

        } catch (error) {
            setMessage(error)
            setOpen(true);

            console.error('Error fetching data:', error);
        }
    };
    const isOlderThanOneDay = (dateString) => {
        const updateDate = new Date(dateString);
        const now = new Date();
        const oneDayInMillis = 24 * 60 * 60 * 1000;
        return (now - updateDate) > oneDayInMillis;
    };
    return (
        <>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    是否執行
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        是否執行指令
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose}>取消</Button>
                    <Button onClick={handleModalOK} autoFocus>
                        執行
                    </Button>
                </DialogActions>
            </Dialog>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>名稱</TableCell>
                            <TableCell>狀態</TableCell>
                            <TableCell>更新時間</TableCell>
                            <TableCell>log</TableCell>
                            <TableCell>操作</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(row => {
                            const cellStyle = {
                                color: isOlderThanOneDay(row.update_at) ? 'red' : 'black'
                            };

                            return (<TableRow key={row.name}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.status}</TableCell>
                                <TableCell style={cellStyle}>{row.update_at}</TableCell>
                                <TableCell style={row.log === '0' ? {color: 'red'} : {}}>{row.log}</TableCell>
                                <TableCell>
                                    {row.name == '上傳google' ? (
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"zh-cn"}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>

                                                <DatePicker
                                                    label="開始時間"
                                                    value={sdate}
                                                    onChange={(newValue) => {
                                                        setSData(newValue);
                                                    }}
                                                />

                                            </DemoContainer>
                                        </LocalizationProvider>) : ("")
                                    }
                                    <Button variant="contained" color="primary"
                                        onClick={confirm_execute.bind(null, row.script)}>
                                        重新執行
                                    </Button>
                                </TableCell>
                            </TableRow>);
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}