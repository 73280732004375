import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Box, Button, Container, Grid, MenuItem, Select} from "@mui/material";
import CreatableAutocomplete from "./CreatableAutocomplete";

const FormComponent = (props) => {
    const [type, setType] = useState(props.record.type || '電影')
    const [theater, setTheater] = useState(props.record.group_name || '威秀')
    const [name, setName] = useState(props.record.name || '')
    const [targetName, setTargetName] = useState(props.record.target_name || '')
    const [theaterOptions, setTheaterOptions] = useState([]);
    const [nameOptions, setNameOptions] = useState([]);
    const [targetNameOptions, setTargetNameOptions] = useState([]);
    const {onSaved} =props

    const fetchData = async () => {
        try {

            const response = await fetch('/api/group-names');
            if (response.ok) {
                const data = await response.json();

                setTheaterOptions(data);
            }

            if (type === '類型') {
                const response = await fetch('/api/movie-types');
                if (response.ok) {
                    const data = await response.json();

                    setNameOptions(data);
                }

                const response2 = await fetch('/api/map-movie-types');
                if (response2.ok) {
                    const data = await response2.json();

                    setTargetNameOptions(data);
                }
            } else if (type === '電影') {
                const response = await fetch('/api/movie-names');
                if (response.ok) {
                    const data = await response.json();

                    setNameOptions(data);
                }

                const response2 = await fetch('/api/merge-movie-info');
                if (response2.ok) {
                    const data = await response2.json();
                    const uniqueMovieNames = [...new Set(data.map(item => item.name))];

                    setTargetNameOptions(uniqueMovieNames);
                }
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        // Fetch your data from an API endpoint using Axios or fetch
        // Replace the URL with your API endpoint


        fetchData();
    }, [type]);

    const handleTheaterChange = (event, newValue) => {
        setTheater(newValue);
    };

    const handleNameChange = (event, newValue) => {
        setName(newValue);
    };
    const handleTargetNameChange = (event, newValue) => {
        setTargetName(newValue);
    };

    const save = () => {
        const apiUrl = '/api/save-map';
        const formData = new FormData();
        formData.append('type', type);
        formData.append('group_name', theater);
        formData.append('name', name);
        formData.append('target_name', targetName);
        fetch(apiUrl, {
            method: 'POST',
            body: formData, // Send the FormData object as the request body
        })
            .then(response => {
                // Check if the response status is OK (200)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Parse the response body as JSON or other appropriate format
                return response.json();
            })
            .then(data => {
                // Handle the response data here

                onSaved()
                // You can perform further actions with the data here
            })
            .catch(error => {
                // Handle errors, such as network issues or invalid JSON
                console.error('Fetch error:', error);
            });
    }

    return (
        <form>
            <Container sx={{paddingTop: 5}}>
                <Grid container space={3}>
                    <Grid container md={12}>
                        <Grid md={2}>
                            <Select value={type} onChange={(e) => setType(e.target.value)} disabled={!!props.record.type}>
                                <MenuItem value="電影">電影</MenuItem>
                                <MenuItem value="類型">類型</MenuItem>
                            </Select>
                        </Grid>
                        <Grid md={3}>
                            <CreatableAutocomplete
                                id="theater"
                                options={theaterOptions}
                                label={"戲院"}
                                value={theater}
                                onChange={handleTheaterChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container md={12}>
                        <Grid md={6}>
                            <CreatableAutocomplete
                                id="movie"
                                options={nameOptions}

                                value={name}
                                onChange={handleNameChange}
                                label={"電影名稱"}
                            />

                        </Grid>
                        <Grid md={6}>
                            <CreatableAutocomplete
                                id="target_movie"
                                options={targetNameOptions}

                                value={targetName}
                                onChange={handleTargetNameChange}
                                label={"對應電影名稱"}
                            />
                        </Grid>
                        <Button onClick={save} variant="contained" color="success">儲存</Button>
                    </Grid>
                </Grid>

            </Container>
        </form>
    );
};

export default FormComponent;