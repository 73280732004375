import React, { useState, useEffect, useRef } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  TextField, 
  Stack,
  CircularProgress,
  Box,
  Fade
} from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const columnWidths = {
  groupName: '12%',
  theaterId: '8%',
  theater: '12%',
  fullName: '15%',
  movieName: '15%',
  movieType: '8%',
  movieDate: '10%',
  movieTime: '10%',
  action: '10%'
};

const RealMoviesManagement = () => {
  const [movies, setMovies] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMovie, setCurrentMovie] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterGroupName, setFilterGroupName] = useState('');
  const [filterTheater, setFilterTheater] = useState('');
  const [filterMovieName, setFilterMovieName] = useState('');
  const [filterMovieType, setFilterMovieType] = useState('');
  const [filteredMovies, setFilteredMovies] = useState([]);
  const [visible, setVisible] = useState(false);
  const containerRef = useRef(null);
  
  // 使用原生 JavaScript 計算預設日期
  const getDefaultDates = () => {
    const end = new Date();
    const start = new Date();
    start.setDate(start.getDate() - 3);
    
    // 格式化日期為 YYYY-MM-DD
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    return {
      startDate: formatDate(start),
      endDate: formatDate(end)
    };
  };

  const { startDate: defaultStartDate, endDate: defaultEndDate } = getDefaultDates();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const fetchMovies = async () => {
    try {
      setLoading(true);
      console.log(`Fetching movies from ${startDate} to ${endDate}`);
      const response = await fetch(`/api/real_movies?start_date=${startDate}&end_date=${endDate}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Fetched data:', data);
      setMovies(data);
    } catch (error) {
      console.error('Failed to fetch movies:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMovies();
  }, [startDate, endDate]);

  // 過濾資料的函數
  useEffect(() => {
    let result = movies;
    
    if (filterGroupName) {
      result = result.filter(movie => 
        movie.group_name?.toLowerCase().includes(filterGroupName.toLowerCase())
      );
    }
    
    if (filterTheater) {
      result = result.filter(movie => 
        movie.theater?.toLowerCase().includes(filterTheater.toLowerCase())
      );
    }
    
    if (filterMovieName) {
      result = result.filter(movie => 
        movie.movie_name?.toLowerCase().includes(filterMovieName.toLowerCase())
      );
    }

    if (filterMovieType) {
      result = result.filter(movie => 
        movie.movie_type?.toLowerCase().includes(filterMovieType.toLowerCase())
      );
    }
    
    setFilteredMovies(result);
  }, [movies, filterGroupName, filterTheater, filterMovieName, filterMovieType]);

  const handleEdit = (movie = null) => {
    setCurrentMovie(movie);
    setIsModalOpen(true);
  };

  const handleSave = async () => {
    try {
      const url = currentMovie && currentMovie.id 
        ? `/api/real_movies/${currentMovie.id}` 
        : '/api/real_movies';
      
      const method = currentMovie && currentMovie.id ? 'PUT' : 'POST';
      
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentMovie)
      });

      if (!response.ok) {
        throw new Error('操作失敗');
      }
      
      fetchMovies();
      setIsModalOpen(false);
    } catch (error) {
      console.error('操作失敗', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`/api/real_movies/${id}`, {
        method: 'DELETE'
      });

      if (!response.ok) {
        throw new Error('刪除失敗');
      }
      
      fetchMovies();
    } catch (error) {
      console.error('刪除失敗', error);
    }
  };

  // 添加滾動監聽
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const Row = React.memo(({ index, style }) => {
    const movie = filteredMovies[index];
    return (
      <div style={{...style, display: 'flex', alignItems: 'center'}}>
        <div style={{width: columnWidths.groupName}}>{movie.group_name}</div>
        <div style={{width: columnWidths.theaterId}}>{movie.theater_id}</div>
        <div style={{width: columnWidths.theater}}>{movie.theater}</div>
        <div style={{width: columnWidths.fullName}}>{movie.full_name}</div>
        <div style={{width: columnWidths.movieName}}>{movie.movie_name}</div>
        <div style={{width: columnWidths.movieType}}>{movie.movie_type}</div>
        <div style={{width: columnWidths.movieDate}}>{movie.movie_date}</div>
        <div style={{width: columnWidths.movieTime}}>{movie.movie_time}</div>
        <div style={{width: columnWidths.action, display: 'flex', gap: '4px'}}>
          <Button 
            variant="outlined" 
            color="primary" 
            size="small"
            onClick={() => handleEdit(movie)}
          >
            編輯
          </Button>
          <Button 
            variant="outlined" 
            color="error" 
            size="small"
            onClick={() => handleDelete(movie.id)}
          >
            刪除
          </Button>
        </div>
      </div>
    );
  });

  return (
    <div ref={containerRef}>
      <Fade in={visible} timeout={1000}>
        <div>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <TextField
              label="開始日期"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="結束日期"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleEdit()}
            >
              新增電影
            </Button>
          </Stack>
          
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <TextField
              label="團體名稱"
              value={filterGroupName}
              onChange={(e) => setFilterGroupName(e.target.value)}
              size="small"
            />
            <TextField
              label="戲院"
              value={filterTheater}
              onChange={(e) => setFilterTheater(e.target.value)}
              size="small"
            />
            <TextField
              label="電影名稱"
              value={filterMovieName}
              onChange={(e) => setFilterMovieName(e.target.value)}
              size="small"
            />
            <TextField
              label="電影類型"
              value={filterMovieType}
              onChange={(e) => setFilterMovieType(e.target.value)}
              size="small"
            />
          </Stack>
          
          <Box sx={{ 
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 200px)', // 調整高度以適應頁面
            width: '100%',
            overflow: 'hidden'
          }}>
            <div style={{
              display: 'flex', 
              borderBottom: '1px solid rgba(224, 224, 224, 1)', 
              padding: '16px 0',
              backgroundColor: '#f5f5f5'
            }}>
              <div style={{width: columnWidths.groupName}}>團體名稱</div>
              <div style={{width: columnWidths.theaterId}}>戲院ID</div>
              <div style={{width: columnWidths.theater}}>戲院</div>
              <div style={{width: columnWidths.fullName}}>電影全名</div>
              <div style={{width: columnWidths.movieName}}>電影名稱</div>
              <div style={{width: columnWidths.movieType}}>電影類型</div>
              <div style={{width: columnWidths.movieDate}}>電影日期</div>
              <div style={{width: columnWidths.movieTime}}>電影時間</div>
              <div style={{width: columnWidths.action}}>操作</div>
            </div>

            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <div style={{ flex: 1, width: '100%' }}>
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      height={height}
                      itemCount={filteredMovies.length}
                      itemSize={53}
                      width={width}
                    >
                      {Row}
                    </List>
                  )}
                </AutoSizer>
              </div>
            )}
          </Box>

          <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <DialogTitle>{currentMovie ? '編輯電影' : '新增電影'}</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                label="團體名稱"
                fullWidth
                value={currentMovie?.group_name || ''}
                onChange={(e) => setCurrentMovie({...currentMovie, group_name: e.target.value})}
              />
              <TextField
                margin="dense"
                label="戲院ID"
                fullWidth
                value={currentMovie?.theater_id || ''}
                onChange={(e) => setCurrentMovie({...currentMovie, theater_id: e.target.value})}
              />
              <TextField
                margin="dense"
                label="戲院"
                fullWidth
                value={currentMovie?.theater || ''}
                onChange={(e) => setCurrentMovie({...currentMovie, theater: e.target.value})}
              />
              <TextField
                margin="dense"
                label="電影全名"
                fullWidth
                value={currentMovie?.full_name || ''}
                onChange={(e) => setCurrentMovie({...currentMovie, full_name: e.target.value})}
              />
              <TextField
                margin="dense"
                label="電影名稱"
                fullWidth
                value={currentMovie?.movie_name || ''}
                onChange={(e) => setCurrentMovie({...currentMovie, movie_name: e.target.value})}
              />
              <TextField
                margin="dense"
                label="電影類型"
                fullWidth
                value={currentMovie?.movie_type || '數位'}
                onChange={(e) => setCurrentMovie({...currentMovie, movie_type: e.target.value})}
              />
              <TextField
                margin="dense"
                label="電影日期"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={currentMovie?.movie_date || ''}
                onChange={(e) => setCurrentMovie({...currentMovie, movie_date: e.target.value})}
              />
              <TextField
                margin="dense"
                label="電影時間"
                fullWidth
                value={currentMovie?.movie_time || ''}
                onChange={(e) => setCurrentMovie({...currentMovie, movie_time: e.target.value})}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsModalOpen(false)} color="primary">
                取消
              </Button>
              <Button onClick={handleSave} color="primary">
                儲存
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Fade>
    </div>
  );
};

export default RealMoviesManagement;
