import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    Button,
    Checkbox, Input,
    MenuItem, Modal,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from '@mui/material';
import {useLoading} from "./LoadingContext";
import MovieMapForm from "./MovieMapForm";
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const columnWidths = {
    type: '10%',
    theater: '15%',
    name: '25%',
    targetName: '25%',
    updateTime: '15%',
    action: '10%'
};

export default function MovieNotMap() {
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [type, setType] = useState("all");
    const [showNull, setShowNull] = useState(true);
    const {showLoading, hideLoading} = useLoading();
    const [modifyRecord, setModifyRecord] = React.useState({});
    const [open, setOpen] = React.useState(false);

    const [filterName, setFilterName] = React.useState("");
    const [filterTargetName, setFilterTargetName] = React.useState("");
    const nameTimeoutId = useRef(null)
    const targetNameTimeoutId = useRef(null)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const loadData = () => {
        showLoading();
        let url = `/api/not-map-moviees?type=${type}&showNull=${showNull}`

        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log('API Response Data:', data[0]); // Log first item to check structure
                setData(data);
                filterContent(data)
                hideLoading();
            })
    }
    useEffect(() => {

        loadData()
    }, [type, showNull])
   useEffect(() => {

        filterContent(data)
    }, [filterTargetName,filterName])



    const edit = (record) => {

        setModifyRecord(record)
        handleOpen()
    }

    const deleteRecord = (record) => {
        if (window.confirm('確定要刪除這筆資料嗎？')) {
            showLoading();
            fetch(`/api/map-movie`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: record.id,
                    type: record.type,
                    name: record.name,
                    target_name: record.target_name,
                    group_name: record.group_name
                })
            })
                .then(response => {
                    if (response.ok) {
                        loadData();
                    } else {
                        alert('刪除失敗');
                    }
                })
                .finally(() => {
                    hideLoading();
                });
        }
    }

    const onSaved = () => {
        handleClose()

        setModifyRecord(null)
        loadData()
    }

    const filterContent=(data)=>{
        let filteredData=data
        if(filterName!=='') {
            filteredData = filteredData.filter(mapinfo => {
                return mapinfo.name.includes(filterName)
            })
        }
        if(filterTargetName!=='') {
            filteredData = filteredData.filter(mapinfo => {
                return mapinfo.target_name.includes(filterTargetName)
            })
        }
        // Sort by update_at in descending order (most recent first)
        filteredData.sort((a, b) => {
            const dateA = new Date(a.update_at);
            const dateB = new Date(b.update_at);
            return dateB - dateA;
        });
        setFilterData((filteredData))
    }

    const handleFilterName=(e) => {

          if(nameTimeoutId.current) {
              clearTimeout(nameTimeoutId.current);
          }

        nameTimeoutId.current = setTimeout(() => {
          setFilterName(e.target.value)
        }, 500);

        // filterContent(data)
    }
    const handleFilterTargetName=(e) => {
        if(targetNameTimeoutId.current) {
            clearTimeout(targetNameTimeoutId.current);
        }

        targetNameTimeoutId.current = setTimeout(() => {
          setFilterTargetName(e.target.value)
        }, 500);
        
        // filterContent(data)
    }

    const Row = React.memo(({ index, style }) => {
        const row = filterData[index];
        return (
            <div style={{...style, display: 'flex', alignItems: 'center'}}>
                <div style={{width: columnWidths.type}}>{row.type}</div>
                <div style={{width: columnWidths.theater}}>{row.group_name}</div>
                <div style={{width: columnWidths.name}}>{row.name}</div>
                <div style={{width: columnWidths.targetName}}>{row.target_name}</div>
                <div style={{width: columnWidths.updateTime}}>{row.update_at}</div>
                <div style={{
                    width: columnWidths.action,
                    display: 'flex',
                    gap: '4px',
                    flexWrap: 'nowrap'
                }}>
                    <Button 
                        onClick={edit.bind(null, row)} 
                        variant="contained"
                        color="success"
                        size="small"
                    >
                        修改
                    </Button>
                    <Button 
                        onClick={() => deleteRecord(row)} 
                        variant="contained"
                        color="error"
                        size="small"
                    >
                        刪除
                    </Button>
                </div>
            </div>
        );
    });

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            height: '100vh',
            width: '100%',
            p: 2
        }}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <MovieMapForm record={modifyRecord} onSaved={onSaved}></MovieMapForm>
                </Box>
            </Modal>
            <div style={{ marginBottom: '16px' }}>
                <Select value={type} onChange={(_, value) => {
                    setType(value.props.value);
                }}>
                    <MenuItem value="all">全部</MenuItem>
                    <MenuItem value="movie">電影</MenuItem>
                    <MenuItem value="type">類型</MenuItem>
                </Select>
                <Checkbox checked={showNull} onChange={(_, value) => setShowNull(value)}/>
                <Button onClick={() => {
                    setModifyRecord({});
                    handleOpen();
                }} variant="contained" color="primary" style={{marginLeft: '10px'}}>新增</Button>
            </div>
            <Box sx={{ 
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                overflow: 'hidden'
            }}>
                <div style={{
                    display: 'flex', 
                    borderBottom: '1px solid rgba(224, 224, 224, 1)', 
                    padding: '16px 0',
                    backgroundColor: '#f5f5f5'
                }}>
                    <div style={{width: columnWidths.type}}>類型</div>
                    <div style={{width: columnWidths.theater}}>影院</div>
                    <div style={{width: columnWidths.name}}>名稱</div>
                    <div style={{width: columnWidths.targetName}}>對應名稱</div>
                    <div style={{width: columnWidths.updateTime}}>最後更新時間</div>
                    <div style={{width: columnWidths.action}}>操作</div>
                </div>
                <div style={{
                    display: 'flex', 
                    padding: '8px 0',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)'
                }}>
                    <div style={{width: columnWidths.type}}></div>
                    <div style={{width: columnWidths.theater}}></div>
                    <div style={{width: columnWidths.name}}>
                        <TextField size="small" onChange={handleFilterName} fullWidth />
                    </div>
                    <div style={{width: columnWidths.targetName}}>
                        <TextField size="small" onChange={handleFilterTargetName} fullWidth />
                    </div>
                    <div style={{width: columnWidths.updateTime}}></div>
                    <div style={{width: columnWidths.action}}></div>
                </div>
                <div style={{ flex: 1, width: '100%' }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                height={height}
                                itemCount={filterData.length}
                                itemSize={53}
                                width={width}
                            >
                                {Row}
                            </List>
                        )}
                    </AutoSizer>
                </div>
            </Box>
        </Box>
    );
}