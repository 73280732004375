import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Box, Button, Container, Grid, MenuItem, Select} from "@mui/material";
import CreatableAutocomplete from "./CreatableAutocomplete";

const FormComponent = (props) => {

    const [excelGroupName, setExcelGroupName] = useState(props.record.excel_group || '')
    const [theaterGroup, setTheaterGroup] = useState(props.record.theater_group || '')
    const {onSaved} =props

    const fetchData = async () => {
        try {

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        // Fetch your data from an API endpoint using Axios or fetch
        // Replace the URL with your API endpoint


        fetchData();
    }, []);


    const save = () => {
        const apiUrl = '/api/update-theater-group';
        const formData = new FormData();
        console.log('Sending data:', { excelGroupName, theaterGroup });
        formData.append('excel_group', excelGroupName);
        formData.append('theater_group', theaterGroup);

        // 檢查 FormData 內容
        for (let pair of formData.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        fetch(apiUrl, {
            method: 'POST',
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Response:', data);
                if (data.result === "儲存成功") {
                    onSaved();
                } else {
                    console.error('Save failed:', data.result);
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    }
     const handleTheaterGroupChange = (event) => {
        setTheaterGroup(event.target.value);
    };
    const handleExcelGroupChange = (event) => {
        setExcelGroupName(event.target.value);
    };
    return (
        <form>
            <Container sx={{paddingTop: 5}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="excel_group"
                            label="Excel群組"
                            value={excelGroupName}
                            onChange={handleExcelGroupChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="theater_group"
                            label="戲院群組"
                            value={theaterGroup}
                            onChange={handleTheaterGroupChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                            <Button onClick={save} variant="contained" color="success">儲存</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </form>
    );
};

export default FormComponent;
