import {Outlet} from "react-router-dom";
import {Box, Grid, List, ListItemButton, ListItemText, Paper, Link} from "@mui/material";
import {useLoginStatus} from "./LoginStatusContext ";
import ImageUploadForm from './ImageUploadForm';
import ImageIcon from '@mui/icons-material/Image';

export default function Root() {
    const {isLoggedIn, login, logout} = useLoginStatus();

    const menuItems = [
        {
            text: '圖片辨識',
            icon: <ImageIcon />,
            path: '/image-upload'
        }
    ];

    return (
        <Box sx={{ display: 'flex' }}>
            <Grid container>
                <Grid item xs={2}>
                    <>
                        <h1>Sony電影</h1>
                        <nav>
                            <Box>
                                <Paper elevation={0}>
                                    <List>

                                        <Link to="/">
                                            <ListItemButton>
                                                <ListItemText primary={"首頁"}/>
                                            </ListItemButton>
                                        </Link>

                                        {isLoggedIn ?(
                                        <>
                                        <Link to="/log">
                                            <ListItemButton>
                                                <ListItemText primary={"日誌"}/>
                                            </ListItemButton>
                                        </Link>
                                        <Link to="/not-map">
                                            <ListItemButton>
                                                <ListItemText primary={"新增無對應項目"}/>
                                            </ListItemButton>
                                        </Link>


                                        <Link to="/merge-info">

                                            <ListItemButton>
                                                <ListItemText primary={"電影清單"}/>
                                            </ListItemButton>
                                        </Link>
                                        <Link to="/similarly-movie">
                                            <ListItemButton>
                                                <ListItemText primary={"相似電影"}/>
                                            </ListItemButton>
                                        </Link>
                                        <Link to="/movie-monitor">
                                            <ListItemButton>
                                                <ListItemText primary={"戲院資料統計"}/>
                                            </ListItemButton>
                                        </Link>
                                         <Link to="/theater-map">
                                            <ListItemButton>
                                                <ListItemText primary={"戲院群組分組"}/>
                                            </ListItemButton>
                                        </Link>
                                        <Link to="/real_movies">
                                            <ListItemButton>
                                                <ListItemText primary={"電影管理"}/>
                                            </ListItemButton>
                                        </Link>
                                        <Link to="/image-upload">
                                            <ListItemButton>
                                                <ListItemText primary={"電影圖片匯入"}/>
                                            </ListItemButton>
                                        </Link>
                                        </>):null}

                                    </List>
                                </Paper>
                            </Box>
                        </nav>
                    </>
                </Grid>
                <Grid item xs={10}>
                    <Outlet/>

                </Grid>
            </Grid>

        </Box>
    )
        ;
}